import {
  createContext,
  useCallback,
  useMemo,
  useState,
  useContext,
} from "react";

import { IOptionsData, ISiderBarContextData } from "side-bar-context";

interface IFuncComponentsProps {
  children?: React.ReactNode;
}

const SideBarContext = createContext({} as ISiderBarContextData);

export const useAppSideBarContext = () => useContext(SideBarContext);

export const SideBarContextProvider: React.FC<IFuncComponentsProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IOptionsData[]>([]);

  const toggleSideBar = useCallback(() => {
    setOpen((last) => !last);
  }, [setOpen]);

  const setDrawerOptions = useCallback(
    (opts: IOptionsData[]) => {
      setOptions(opts);
    },
    [setOptions]
  );

  const isOpen = useMemo(() => open, [open]);
  const drawerOptions = useMemo(() => options, [options]);

  return (
    <SideBarContext.Provider
      value={{ isOpen, drawerOptions, toggleSideBar, setDrawerOptions }}
    >
      {children}
    </SideBarContext.Provider>
  );
};
