import { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  DialogContentText,
  FormControlLabel,
  Paper,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { IIntent } from "data-context";
import { ITableItemHeader } from "table-item";

import { BaseLayout } from "../../shared/layouts";
import { ActionsDialog, TableItem } from "../../shared/components";
import {
  deleteIntent,
  retrieveIntents,
  updateIntent,
} from "../../shared/services";
import {
  useAppAlertContext,
  useAppThemeContext,
  useAppUserContext,
} from "../../shared/contexts";

const _tableHeaders: ITableItemHeader[] = [
  { kind: "string", label: "Intenção", key: "intent", filter: true },
  { kind: "string", label: "Tag", key: "tag", filter: true },
  { kind: "boolean", label: "Finaliza", key: "end", filter: true },
];

type TDialogKind = "insert" | "update" | "remove";

interface IDialog {
  kind?: TDialogKind;
  intent?: string;
  tag?: string;
  end?: boolean;
}

export const IntentsPage = () => {
  const { email } = useAppUserContext();
  const { theme } = useAppThemeContext();
  const { showAlert } = useAppAlertContext();

  const [data, setData] = useState<IIntent[]>();

  const [dialog, setDialog] = useState<IDialog>({});

  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const InsertDialog: React.FC = () => {
    const tag = useRef<string>();
    const intent = useRef<string>();
    const end = useRef(false);

    const insert: () => Promise<void> = () => {
      return new Promise((resolve, reject) => {
        if (
          !intent.current ||
          !tag.current ||
          intent.current === "" ||
          tag.current === ""
        ) {
          setDialog({});

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Ocorreu um erro ao criar a intenção.",
          });

          return resolve();
        }

        if (!email) {
          setDialog({});

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Você não esta autenticado para executar esta ação.",
          });

          return resolve();
        }

        updateIntent(email, intent.current, {
          tag: tag.current,
          end: end.current,
        } as IIntent)
          .then(() => {
            setDialog({});

            showAlert({
              severity: "success",
              title: "Sucesso",
              message: "A intenção foi criada com sucesso",
            });
          })
          .catch((err) => {
            setDialog({});

            console.error(err);

            showAlert({
              severity: "error",
              title: "Falha",
              message: "Ocorreu um erro ao criar a intenção.",
            });
          })
          .finally(() => {
            loadIntents();
            resolve();
          });
      });
    };

    const Fields: React.FC = () => {
      return (
        <Box
          display="flex"
          flexDirection="column"
          gap={theme.spacing(2)}
          marginTop={theme.spacing(2)}
          alignItems="center"
        >
          <TextField
            label="Intenção"
            variant="outlined"
            value={intent.current}
            onChange={(e) => (intent.current = e.target.value)}
          />
          <TextField
            label="Tag"
            variant="outlined"
            value={tag.current}
            onChange={(e) => (tag.current = e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox onChange={(e) => (end.current = e.target.checked)} />
            }
            label="Finaliza?"
          />
        </Box>
      );
    };

    return (
      <ActionsDialog
        title="Criar Intenção"
        open={dialog.kind === "insert"}
        onConfirm={() => insert()}
        onClose={() => setDialog({})}
        onCancel={() => setDialog({})}
        confirmLabel="Criar"
        cancelLabel="Cancelar"
        showLoading={true}
        transitionDirection="up"
      >
        <DialogContentText>
          Digite a intenção e tag para criar.
        </DialogContentText>
        <Fields />
      </ActionsDialog>
    );
  };

  const UpdateDialog: React.FC = () => {
    const { intent, tag: initialTag, end: initialEnd } = dialog;
    const tag = useRef<string>();
    const end = useRef<boolean>();

    const update: () => Promise<void> = () => {
      return new Promise((resolve, reject) => {
        if (!intent || intent === "" || !tag.current || tag.current === "") {
          setDialog({});

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Ocorreu um erro ao atualizar a intenção.",
          });

          return resolve();
        }

        if (!email) {
          setDialog({});

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Você não esta autenticado para executar esta ação.",
          });

          return resolve();
        }

        updateIntent(email, intent, {
          tag: tag.current,
          end: end.current,
        } as IIntent)
          .then(() => {
            setDialog({});

            showAlert({
              severity: "success",
              title: "Sucesso",
              message: "A intenção foi atualizada com sucesso",
            });
          })
          .catch((err) => {
            setDialog({});

            console.error(err);

            showAlert({
              severity: "error",
              title: "Falha",
              message: "Ocorreu um erro ao atualizar a intenção.",
            });
          })
          .finally(() => {
            loadIntents();
            resolve();
          });
      });
    };

    const Fields: React.FC = () => {
      const [check, setCheck] = useState(end.current);

      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={theme.spacing(2)}
          marginTop={theme.spacing(2)}
        >
          <TextField
            label="Tag"
            variant="outlined"
            value={tag.current}
            onChange={(e) => (tag.current = e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={check}
                onChange={(e) => {
                  setCheck(e.target.checked);
                  end.current = e.target.checked;
                }}
              />
            }
            label="Finaliza?"
          />
        </Box>
      );
    };

    return (
      <ActionsDialog
        title="Atualizar Intenção"
        open={dialog.kind === "update"}
        onConfirm={() => update()}
        onClose={() => setDialog({})}
        onCancel={() => setDialog({})}
        confirmLabel="Atualizar"
        cancelLabel="Cancelar"
        showLoading={true}
        transitionDirection="up"
      >
        <DialogContentText>
          {`Digite a tag para atualizar a inteção: "${dialog.intent}"`}
        </DialogContentText>
        <Fields />
      </ActionsDialog>
    );
  };

  const RemoveDialog: React.FC = () => {
    const { intent } = dialog;

    const remove: () => Promise<void> = () => {
      return new Promise((resolve, reject) => {
        if (!intent || intent === "") {
          setDialog({});

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Ocorreu um erro ao deletar a intenção.",
          });

          return resolve();
        }

        if (!email) {
          setDialog({});

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Você não esta autenticado para executar esta ação.",
          });

          return resolve();
        }

        deleteIntent(email, intent)
          .then(() => {
            setDialog({});

            showAlert({
              severity: "success",
              title: "Sucesso",
              message: "A intenção foi deletada com sucesso",
            });
          })
          .catch((err) => {
            setDialog({});

            console.error(err);

            showAlert({
              severity: "error",
              title: "Falha",
              message: "Ocorreu um erro ao deletar a intenção.",
            });
          })
          .finally(() => {
            loadIntents();
            resolve();
          });
      });
    };

    return (
      <ActionsDialog
        title="Deletar Intenção"
        open={dialog.kind === "remove"}
        onConfirm={() => remove()}
        onClose={() => setDialog({})}
        onCancel={() => setDialog({})}
        confirmLabel="Deletar"
        cancelLabel="Cancelar"
        showLoading={true}
        transitionDirection="up"
      >
        <DialogContentText>
          {`Deseja deletar a inteção: "${intent}"`}
        </DialogContentText>
      </ActionsDialog>
    );
  };

  function loadIntents() {
    if (!email) {
      setDialog({});

      showAlert({
        severity: "error",
        title: "Falha",
        message: "Você não esta autenticado para executar esta ação.",
      });

      return;
    }

    retrieveIntents(email)
      .then((intents) => {
        if (!intents) return;

        const tmp = [];

        for (const key of Object.keys(intents)) {
          if (intents.hasOwnProperty(key) && typeof key === "string") {
            const { tag, end } = intents[key];
            tmp.push({ intent: key, tag, end });
          }
        }

        setData(tmp);

        showAlert({
          severity: "success",
          title: "Sucesso",
          message: "Intenções coletadas com sucesso.",
        });
      })
      .catch((err) => {
        console.error(err);

        setData([]);

        showAlert({
          severity: "error",
          title: "Falha",
          message: "Ocorreu um erro na apresentação dos dados.",
        });
      });
  }

  useEffect(loadIntents, []);

  return (
    <BaseLayout title="Intenções">
      <>
        <Paper
          sx={{
            height: "100%",
            padding: theme.spacing(1),
          }}
        >
          <TableItem
            title={`Intençõe(s) => Tag(s)`}
            headers={_tableHeaders}
            rawData={data || []}
            noData={
              data === undefined ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ margin: theme.spacing(2) }}
                  height="100%"
                  width="100%"
                />
              ) : (
                <Typography
                  sx={{ margin: "auto" }}
                  variant={smDown ? "h5" : mdDown ? "h4" : "h3"}
                >
                  Sem dados para exibir
                </Typography>
              )
            }
            actions={{
              insert: data !== undefined,
              update: true,
              remove: true,
              download: false,
            }}
            onInsert={() => setDialog({ kind: "insert" })}
            onUpdate={(intent) => setDialog({ ...intent, kind: "update" })}
            onRemove={(intent) => setDialog({ ...intent, kind: "remove" })}
          />
        </Paper>
        <InsertDialog />
        <UpdateDialog />
        <RemoveDialog />
      </>
    </BaseLayout>
  );
};
