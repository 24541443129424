import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import {
  Icon,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { IListItemLinkProps } from "list-item";

export const ListItemLink: React.FC<IListItemLinkProps> = ({
  label,
  icon,
  to,
  onClick,
}) => {
  const navigate = useNavigate();

  const resolvedPath = useResolvedPath(to || "");
  const match = useMatch({ path: resolvedPath.pathname, end: false });

  const handleClick = () => {
    to && navigate(to);
    onClick?.();
  };

  return (
    <ListItemButton
      sx={{ textOverflow: "ellipsis" }}
      selected={!!to && !!match}
      onClick={() => handleClick()}
    >
      <ListItemIcon>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      {label && (
        <ListItemText
          sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          primary={label}
        ></ListItemText>
      )}
    </ListItemButton>
  );
};
