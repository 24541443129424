import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { App } from "./App";
import {
  AppThemeProvider,
  UserContextProvider,
  DataContextProvider,
  AlertContextProvider,
  SideBarContextProvider,
} from "./shared/contexts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <UserContextProvider>
        <AlertContextProvider>
          <SideBarContextProvider>
            <DataContextProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </DataContextProvider>
          </SideBarContextProvider>
        </AlertContextProvider>
      </UserContextProvider>
    </AppThemeProvider>
  </React.StrictMode>
);
