import { TransitionProps } from "@mui/material/transitions";

import { forwardRef, useState } from "react";
import {
  useTheme,
  Slide,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";

import { IActionsDialogProps } from "actions-dialog";

export const ActionsDialog: React.FC<IActionsDialogProps> = ({
  title,
  open,
  children,
  onConfirm,
  onClose,
  onCancel,
  confirmLabel,
  cancelLabel,
  showLoading,
  transitionDirection,
}) => {
  const theme = useTheme();

  const _transition = forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction={transitionDirection} ref={ref} {...props} />;
  });

  const Actions: React.FC = () => {
    const [loading, setLoading] = useState(false);

    const handleConfirm = () => {
      if (showLoading === true) setLoading(true);
      onConfirm().finally(() => setLoading(false));
    };

    return (
      <DialogActions>
        {loading ? (
          <CircularProgress sx={{ marginRight: theme.spacing(1) }} />
        ) : (
          <>
            <Button onClick={() => onCancel()}>
              {cancelLabel || "Cancel"}
            </Button>
            <Button onClick={() => handleConfirm()}>
              {confirmLabel || "Confirm"}
            </Button>
          </>
        )}
      </DialogActions>
    );
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={_transition}
      onClose={() => onClose()}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <Actions />
    </Dialog>
  );
};
