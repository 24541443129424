import { IListTreeItem } from "list-tree";

export const foundTag = (
  data: Array<IListTreeItem>,
  tag: string
): IListTreeItem | undefined => {
  for (const d of data) {
    if (d.name === tag) return d;

    if (d.childrens === undefined || d.childrens.length === 0) continue;

    const res = foundTag(d.childrens, tag);

    if (res !== undefined) return res;
  }
  return undefined;
};

export const foundParent = (
  data: IListTreeItem,
  tag: string
): IListTreeItem | undefined => {
  if (data.childrens === undefined) return undefined;

  if (data.childrens.filter((c) => c.name === tag).length > 0) return data;

  for (const c of data.childrens) {
    const res = foundParent(c, tag);

    if (res !== undefined) return res;
  }
  return undefined;
};
