import { Box, Paper, Skeleton, Typography, useMediaQuery } from "@mui/material";

import { IDataLayoutPageProps } from "data-layout";

import { BaseLayout } from "./BaseLayout";
import { useAppThemeContext, useAppDataContext } from "../contexts";

export const DataLayout: React.FC<IDataLayoutPageProps> = ({
  title,
  children,
  hasData,
  showAgentFilter,
  showIntervalFilter,
  loading,
}) => {
  const { theme } = useAppThemeContext();
  const { isLoading } = useAppDataContext();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <BaseLayout
      title={title}
      showAgentFilter={showAgentFilter}
      showIntervalFilter={showIntervalFilter}
    >
      <Box height="100%" maxHeight="100%" display="flex" flexDirection="column">
        {isLoading === true || loading === true ? (
          <Skeleton variant="rectangular" sx={{ flexGrow: 1 }} />
        ) : (
          <Paper
            sx={{
              flexGrow: 1,
              padding: theme.spacing(1),
              overflow: "auto",
              maxHeight: "100%",
            }}
          >
            {hasData === false ? (
              <Box
                height="100%"
                width="100%"
                alignContent="center"
                display="flex"
              >
                <Typography
                  sx={{ margin: "auto" }}
                  variant={smDown ? "h6" : mdDown ? "h5" : "h4"}
                >
                  Sem dados para exibir
                </Typography>
              </Box>
            ) : (
              children
            )}
          </Paper>
        )}
      </Box>
    </BaseLayout>
  );
};
