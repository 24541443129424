import { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Fab,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { IBaseLayoutPageProps } from "base-layout";

import { SideBar } from "../components";
import { Solve4MeLogo } from "../assets";
import {
  useAppThemeContext,
  useAppDataContext,
  useAppAlertContext,
  useAppUserContext,
} from "../contexts";

type TFontSize =
  | "button"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "inherit"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "overline";

export const BaseLayout: React.FC<IBaseLayoutPageProps> = ({
  title,
  children,
  showAgentFilter,
  showIntervalFilter,
}) => {
  const { email } = useAppUserContext();
  const { theme } = useAppThemeContext();
  const { isShowing } = useAppAlertContext();
  const {
    showDateFilter,
    isLoading,
    agent,
    agents,
    setAgent,
    interval,
    loadData,
  } = useAppDataContext();

  const [fontSize, setFontSize] = useState<TFontSize>();
  const [appBarHeight, setAppBarHeight] = useState<string>();

  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setAppBarHeight(
      smDown ? theme.spacing(5) : mdDown ? theme.spacing(6) : theme.spacing(7)
    );

    setFontSize(smDown ? "h6" : mdDown ? "h5" : "h4");
  }, [smDown, mdDown, theme]);

  return (
    <Box
      height="100vh"
      width="100vw"
      maxHeight="100vh"
      maxWidth="100vw"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      {email ? (
        <>
          <AppBar
            position="fixed"
            sx={{
              display: "flex",
              flexDirection: "row",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <Typography
              whiteSpace={"nowrap"}
              textOverflow="ellipses"
              variant={fontSize}
              flex={1}
              margin={theme.spacing(1)}
              fontWeight="bold"
            >
              Bot Tag
            </Typography>

            <Box flexGrow={1} />

            <Box margin="auto" marginRight={theme.spacing(4)}>
              {showAgentFilter === true && (
                <FormControl
                  sx={{
                    width: theme.spacing(20),
                    minWidth: theme.spacing(15),
                  }}
                  disabled={isLoading || isShowing || agents?.length === 0}
                >
                  <InputLabel>Agente</InputLabel>
                  <Select
                    label="Agente"
                    value={agent || ""}
                    onChange={(e) => setAgent(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {agents.map((o, i) => (
                      <MenuItem key={`menu-item-agent-key-${i}`} value={o}>
                        {o}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>

            <Box margin="auto" marginRight={theme.spacing(4)}>
              {showIntervalFilter === true && (
                <Box display="flex" gap={theme.spacing(2)}>
                  <Tooltip title="Filtro">
                    <Fab
                      size={smDown ? "small" : "medium"}
                      color="inherit"
                      onClick={() => showDateFilter()}
                      disabled={isLoading || isShowing}
                    >
                      <Icon sx={{ color: theme.palette.primary.main }}>
                        filter_alt_icon
                      </Icon>
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Atualizar">
                    <Fab
                      size={smDown ? "small" : "medium"}
                      color="inherit"
                      onClick={() => loadData()}
                      disabled={
                        isLoading ||
                        isShowing ||
                        interval === undefined ||
                        interval.startDate === undefined ||
                        interval.endDate === undefined
                      }
                    >
                      <Icon sx={{ color: theme.palette.primary.main }}>
                        refresh
                      </Icon>
                    </Fab>
                  </Tooltip>
                </Box>
              )}
            </Box>

            <Box margin="auto">
              <Solve4MeLogo fill="#FFF" height={appBarHeight} />
            </Box>
          </AppBar>

          <Box height={appBarHeight} />

          <Box flexGrow={1}>
            <SideBar marginTop={appBarHeight}>
              <Box
                flexGrow={1}
                display="flex"
                flexDirection="column"
                overflow="hidden"
                sx={{
                  padding: theme.spacing(2),
                }}
              >
                <Paper
                  sx={{
                    padding: theme.spacing(2),
                    marginBottom: theme.spacing(1),
                  }}
                >
                  <Typography
                    whiteSpace={"nowrap"}
                    textOverflow="ellipses"
                    variant={fontSize}
                  >
                    {title}
                  </Typography>
                </Paper>
                <Box flexGrow={1}>{children}</Box>
              </Box>
            </SideBar>
          </Box>
        </>
      ) : (
        <Skeleton variant="rectangular" sx={{ flexGrow: 1 }} />
      )}
    </Box>
  );
};
