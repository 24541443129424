import { Box } from "@mui/material";
import WordCloud from "react-d3-cloud";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DataLayout } from "../../shared/layouts";
import {
  useAppAlertContext,
  useAppDataContext,
  useAppThemeContext,
} from "../../shared/contexts";

export const WordCloudPage = () => {
  const { theme } = useAppThemeContext();
  const { graphsData } = useAppDataContext();
  const { showAlert, isShowing } = useAppAlertContext();

  const refElement = useRef<HTMLDivElement>();

  const [graphSize, setGraphSize] = useState<[number, number]>();

  const colors = useMemo(
    () => ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    []
  );
  const angles = useMemo(() => [0], []);

  const fontSize = useCallback((word: any) => Math.sqrt(word.value) * 10, []);
  const rotate = useCallback(
    () =>
      angles[
        Math.min(Math.floor(Math.random() * angles.length), angles.length - 1)
      ],
    [angles]
  );
  const fill = useCallback(
    (d: any, i: number) => colors[Math.ceil(i % colors.length)],
    [colors]
  );

  useEffect(() => {
    function updateSize() {
      if (
        refElement === null ||
        refElement === undefined ||
        refElement.current === undefined
      )
        return;

      setGraphSize([
        refElement.current?.clientHeight,
        refElement.current?.clientWidth,
      ]);
    }

    updateSize();

    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, [graphsData]);

  return (
    <DataLayout
      title="Nuvem de palavras"
      hasData={graphsData?.wordCloudData?.length > 0 || graphSize !== undefined}
      showAgentFilter={true}
      showIntervalFilter={true}
    >
      <Box
        height="100%"
        width="100%"
        maxHeight={theme.spacing(85)}
        maxWidth="100%"
        alignItems="center"
        justifyContent="center"
        overflow="auto"
        ref={refElement}
      >
        {useMemo(
          () =>
            graphSize && (
              <WordCloud
                height={graphSize[0]}
                width={graphSize[1]}
                font="impact"
                spiral="rectangular"
                fontSize={fontSize}
                rotate={rotate}
                padding={5}
                data={graphsData?.wordCloudData || []}
                onWordClick={(event, d) => {
                  if (!isShowing)
                    showAlert({
                      title: `${d.text}`,
                      severity: "info",
                      message: `${d.value}`,
                    });
                }}
                fill={fill}
              />
            ),
          [graphsData, graphSize]
        )}
      </Box>
    </DataLayout>
  );
};
