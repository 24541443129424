import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import Chart from "react-google-charts";
import Pagination from "@mui/material/Pagination";

import { DataLayout } from "../../shared/layouts";
import { useAppThemeContext, useAppDataContext } from "../../shared/contexts";

const _title = ["Tags", "Quantidade"];

const _limit = 15;

export const BarGraphPage = () => {
  const { theme } = useAppThemeContext();
  const { graphsData, interval } = useAppDataContext();
  const [page, setPage] = useState(1);

  const data = useMemo(
    () => graphsData?.barGraphData.slice((page - 1) * _limit, page * _limit),
    [page, graphsData]
  );

  return (
    <DataLayout
      title="Gráfico de barras"
      hasData={graphsData?.barGraphData?.length > 0}
      showAgentFilter={true}
      showIntervalFilter={true}
    >
      <Box
        height="100%"
        width="100%"
        maxHeight={theme.spacing(85)}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          overflowY: "auto",
        }}
      >
        <Chart
          chartType="BarChart"
          width="100%"
          height="95%"
          data={[_title, ...(data || [])]}
          options={{
            title: `Tag(s) no periodo: ${interval.startDate?.toLocaleString()} até ${interval.endDate?.toLocaleString()}`,
            bar: { groupWidth: "95%" },
            legend: { position: "none" },
            colors: [theme.palette.secondary.main],
            hAxis: {
              title: _title[1],
              minValue: 0,
              format: "",
            },
            vAxis: {
              title: _title[0],
            },
          }}
          legendToggle
        />
        <Pagination
          count={Math.ceil(graphsData?.barGraphData.length / _limit)}
          shape="rounded"
          onChange={(e, v) => setPage(v)}
        />
      </Box>
    </DataLayout>
  );
};
