import { createContext, useContext, useEffect, useState } from "react";

import { IUserContextData } from "user-context";

import { oAuth } from "../services/PureCloud";

interface IFuncComponentsProps {
  children: React.ReactNode;
}

const UserContext = createContext({} as IUserContextData);

export const useAppUserContext = () => useContext(UserContext);

export const UserContextProvider: React.FC<IFuncComponentsProps> = ({
  children,
}) => {
  const [email, setEmail] = useState<string>();

  useEffect(() => {
    oAuth()
      .then((res) => {
        if (res.status && res.email) setEmail(res.email);
      })
      .catch(console.error);
  }, []);

  return (
    <UserContext.Provider value={{ email }}>{children}</UserContext.Provider>
  );
};
