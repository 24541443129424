import { useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Skeleton,
  Toolbar,
  Tooltip,
  Zoom,
  Fab,
  Icon,
  useMediaQuery,
  DialogContentText,
  TextField,
} from "@mui/material";

import { ITagLevel } from "data-context";
import { IListTreeItem } from "list-tree";

import { BaseLayout } from "../../shared/layouts";
import { foundTag, foundParent } from "../../shared/libs";
import { ListTree, ActionsDialog } from "../../shared/components";
import { retrieveTagsLevels, updateTagsLevels } from "../../shared/services";
import {
  useAppAlertContext,
  useAppThemeContext,
  useAppUserContext,
} from "../../shared/contexts";

interface ITagsLevelsActions {
  insert?: boolean;
  update?: boolean;
  remove?: boolean;
}

type TActionKind = "insert" | "update" | "remove" | "save";

export const TagsLevelsPage = () => {
  const { email } = useAppUserContext();
  const { theme } = useAppThemeContext();
  const { showAlert } = useAppAlertContext();
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState<TActionKind>();
  const [data, setData] = useState<Array<ITagLevel>>([]);
  const [selected, setSelected] = useState<IListTreeItem>();
  const [allowedActions, setAllowedActions] = useState<ITagsLevelsActions>({});

  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const InsertDialog: React.FC = () => {
    const tag = useRef<string>();

    const insert: () => Promise<void> = () => {
      return new Promise((resolve, reject) => {
        const name = tag.current;

        if (selected === undefined || name === undefined) {
          setDialog(undefined);

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Ocorreu um erro ao criar o nivel de tag.",
          });

          return resolve();
        }

        if (name === "Main") {
          setDialog(undefined);

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Não é possivel criar uma tag com o nome 'Main'.",
          });

          return resolve();
        }

        if (foundTag(data, name) !== undefined) {
          setDialog(undefined);

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Nivel de tag já existente",
          });

          return resolve();
        }

        try {
          if (selected.name === "Main") data.push({ name });
          else selected.childrens = [...(selected.childrens || []), { name }];
          setDialog(undefined);

          showAlert({
            severity: "success",
            title: "Sucesso",
            message: "Nivel de tag criado com sucesso",
          });

          setData(data);
          handleSelected();
        } catch (err) {
          setDialog(undefined);

          console.error(err);

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Ocorreu um erro ao criar o nivel de tag.",
          });
        } finally {
          return resolve();
        }
      });
    };

    const Fields: React.FC = () => {
      return (
        <Box display="flex" flexDirection="column">
          <TextField
            sx={{ marginTop: theme.spacing(2) }}
            label="Tag"
            variant="outlined"
            value={tag.current}
            onChange={(e) => (tag.current = e.target.value)}
          />
        </Box>
      );
    };

    return (
      <ActionsDialog
        title="Criar nivel de tag"
        open={dialog === "insert"}
        onConfirm={() => insert()}
        onClose={() => setDialog(undefined)}
        onCancel={() => setDialog(undefined)}
        confirmLabel="Criar"
        cancelLabel="Cancelar"
        showLoading={true}
        transitionDirection="up"
      >
        <DialogContentText>
          {`Deseja criar uma tag abaixo de: "${selected?.name}"?`}
        </DialogContentText>
        <Fields />
      </ActionsDialog>
    );
  };

  const UpdateDialog: React.FC = () => {
    const tag = useRef<string>();

    const update: () => Promise<void> = () => {
      return new Promise((resolve, reject) => {
        const name = tag.current;

        if (selected === undefined || name === undefined) {
          setDialog(undefined);

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Ocorreu um erro ao atualizar o nivel de tag.",
          });

          return resolve();
        }

        if (foundTag(data, name) !== undefined) {
          setDialog(undefined);

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Nivel de tag já existente",
          });

          return resolve();
        }

        try {
          selected.name = name;

          setDialog(undefined);

          showAlert({
            severity: "success",
            title: "Sucesso",
            message: "Nivel de tag atualizado com sucesso",
          });

          setData(data);
          handleSelected();
        } catch (err) {
          setDialog(undefined);

          console.error(err);

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Ocorreu um erro ao atualizar o nivel de tag.",
          });
        } finally {
          return resolve();
        }
      });
    };

    const Fields: React.FC = () => {
      return (
        <Box display="flex" flexDirection="column">
          <TextField
            sx={{ marginTop: theme.spacing(2) }}
            label="Tag"
            variant="outlined"
            value={tag.current}
            onChange={(e) => (tag.current = e.target.value)}
          />
        </Box>
      );
    };

    return (
      <ActionsDialog
        title="Atualizar nivel de tag"
        open={dialog === "update"}
        onConfirm={() => update()}
        onClose={() => setDialog(undefined)}
        onCancel={() => setDialog(undefined)}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        showLoading={true}
        transitionDirection="up"
      >
        <DialogContentText>
          {`Digite a tag para atualizar o nome de: "${selected?.name}"`}
        </DialogContentText>
        <Fields />
      </ActionsDialog>
    );
  };

  const RemoveDialog: React.FC = () => {
    const remove: () => Promise<void> = () => {
      return new Promise((resolve, reject) => {
        if (selected === undefined) {
          setDialog(undefined);

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Ocorreu um erro ao deletar o nivel de tag.",
          });

          return resolve();
        }

        const { name } = selected;
        let founded: IListTreeItem | undefined;

        for (const d of data) {
          if (d.name === name) founded = { name: "Main", childrens: data };
          else founded = foundParent(d, name);

          if (founded !== undefined) break;
        }

        if (founded === undefined || founded.childrens === undefined) {
          console.error("Not Founded the parent of the tag level");

          setDialog(undefined);

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Ocorreu um erro ao deletar o nivel de tag.",
          });

          return resolve();
        }

        try {
          let tmp = data;
          if (founded.name === "Main")
            tmp = data.filter((c) => c.name !== name);
          else
            founded.childrens = founded.childrens.filter(
              (c) => c.name !== name
            );

          setDialog(undefined);

          showAlert({
            severity: "success",
            title: "Sucesso",
            message: "Nivel de tag deletado com sucesso",
          });

          setData(tmp);
          handleSelected();
        } catch (err) {
          setDialog(undefined);

          console.error(err);

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Ocorreu um erro ao deletar o nivel de tag.",
          });
        } finally {
          return resolve();
        }
      });
    };

    return (
      <ActionsDialog
        title="Deletar nivel de tag"
        open={dialog === "remove"}
        onConfirm={() => remove()}
        onClose={() => setDialog(undefined)}
        onCancel={() => setDialog(undefined)}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        showLoading={true}
        transitionDirection="up"
      >
        <DialogContentText>
          {`Deseja deletar o nivel de tag: "${selected?.name}"?`}
        </DialogContentText>
      </ActionsDialog>
    );
  };

  const SaveDialog: React.FC = () => {
    const save: () => Promise<void> = () => {
      return new Promise((resolve, reject) => {
        if (!email) {
          setDialog(undefined);

          showAlert({
            severity: "error",
            title: "Falha",
            message: "Você não esta autenticado para executar esta ação.",
          });

          return resolve();
        }

        updateTagsLevels(email, data)
          .then(async () => {
            setDialog(undefined);

            showAlert({
              severity: "success",
              title: "Sucesso",
              message: "Os niveis de tags foram atualizados com sucesso.",
            });

            await new Promise((resolve, reject) => setTimeout(resolve, 1000));
          })
          .catch((err) => {
            setDialog(undefined);

            console.error(err);

            showAlert({
              severity: "error",
              title: "Falha",
              message: "Ocorreu um erro ao salvar os niveis das tags.",
            });
          })
          .finally(() => {
            loadTagsLevels();
            resolve();
          });
      });
    };

    return (
      <ActionsDialog
        title="Salvar niveis das tags"
        open={dialog === "save"}
        onConfirm={() => save()}
        onClose={() => setDialog(undefined)}
        onCancel={() => setDialog(undefined)}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        showLoading={true}
        transitionDirection="up"
      >
        <DialogContentText>Deseja salvar os niveis das tags?</DialogContentText>
      </ActionsDialog>
    );
  };

  const loadTagsLevels = () => {
    setLoading(true);

    if (!email) {
      setDialog(undefined);

      showAlert({
        severity: "error",
        title: "Falha",
        message: "Você não esta autenticado para executar esta ação.",
      });
      setLoading(false);

      return;
    }

    retrieveTagsLevels(email)
      .then((tagsLevels) => {
        if (!tagsLevels) return;

        setData(tagsLevels as Array<ITagLevel>);

        showAlert({
          severity: "success",
          title: "Sucesso",
          message: "Niveis de tags coletados com sucesso.",
        });
      })
      .catch((err) => {
        console.error(err);

        setData([]);

        showAlert({
          severity: "error",
          title: "Falha",
          message: "Ocorreu um erro na coleta dos niveis das tags.",
        });
      })
      .finally(() => setLoading(false));
  };

  const handleSelected = (tag?: string) => {
    if (tag === "Main") {
      setSelected({ name: "Main", childrens: data });
      return setAllowedActions({ insert: true });
    }

    if (tag === undefined || tag === "") {
      setSelected(undefined);
      return setAllowedActions({});
    }

    const founded = foundTag(data, tag);
    setSelected(founded);

    if (founded === undefined) return setAllowedActions({});

    if (founded.childrens === undefined || founded.childrens.length === 0)
      return setAllowedActions({ insert: true, update: true, remove: true });

    return setAllowedActions({ insert: true, update: true });
  };

  useEffect(loadTagsLevels, []);

  return (
    <BaseLayout title="Niveis de tags">
      <Box height="100%" display="flex">
        <Paper
          sx={{
            padding: theme.spacing(2),
            flexGrow: 1,
          }}
        >
          {loading ? (
            <Skeleton
              variant="rectangular"
              sx={{ margin: theme.spacing(2) }}
              height="100%"
            />
          ) : (
            <Box height="100%" width="100%">
              <Toolbar sx={{ display: "flex", marginBottom: theme.spacing(4) }}>
                <Box display="flex" gap={theme.spacing(2)}>
                  {allowedActions.insert === true && (
                    <Zoom in={true}>
                      <Tooltip
                        title="Insert"
                        onClick={() => setDialog("insert")}
                      >
                        <Fab size={lgDown ? "small" : "medium"} color="primary">
                          <Icon>add</Icon>
                        </Fab>
                      </Tooltip>
                    </Zoom>
                  )}

                  {allowedActions.update === true && (
                    <Zoom in={true}>
                      <Tooltip
                        title="Update"
                        onClick={() => setDialog("update")}
                      >
                        <Fab size={lgDown ? "small" : "medium"} color="primary">
                          <Icon>edit</Icon>
                        </Fab>
                      </Tooltip>
                    </Zoom>
                  )}

                  {allowedActions.remove === true && (
                    <Zoom in={true}>
                      <Tooltip
                        title="Remove"
                        onClick={() => setDialog("remove")}
                      >
                        <Fab size={lgDown ? "small" : "medium"} color="primary">
                          <Icon>delete</Icon>
                        </Fab>
                      </Tooltip>
                    </Zoom>
                  )}
                </Box>
                <Box flexGrow={1} />
                <Box>
                  <Tooltip
                    title="Save"
                    sx={{ marginRight: theme.spacing(1) }}
                    onClick={() => setDialog("save")}
                  >
                    <Fab
                      size={lgDown ? "small" : "medium"}
                      color="primary"
                      aria-label="add"
                    >
                      <Icon>save</Icon>
                    </Fab>
                  </Tooltip>
                </Box>
              </Toolbar>
              <ListTree data={data} onSelected={(e) => handleSelected(e)} />
            </Box>
          )}
        </Paper>
        <SaveDialog />
        <InsertDialog />
        <UpdateDialog />
        <RemoveDialog />
      </Box>
    </BaseLayout>
  );
};
