import TreeView from "@mui/lab/TreeView";
import Collapse from "@mui/material/Collapse";
import { alpha, styled } from "@mui/material/styles";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import { Icon, Box } from "@mui/material";

import { IListTreeProps, IListTreeItem } from "list-tree";

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={Collapse} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export const ListTree: React.FC<IListTreeProps> = ({ data, onSelected }) => {
  let nodeId = 0;

  const formatTreeItem = (data: Array<IListTreeItem>) => {
    return data.map(({ name, childrens }, index) => {
      nodeId += 1;

      return (
        <StyledTreeItem
          key={`tree-item-${nodeId}`}
          nodeId={name}
          label={name}
          children={
            childrens && childrens?.length > 0
              ? formatTreeItem(childrens)
              : undefined
          }
        />
      );
    });
  };

  return (
    <Box height="100%" width="100%">
      {data && (
        <TreeView
          defaultCollapseIcon={<Icon>expand_more</Icon>}
          defaultExpandIcon={<Icon>chevron_right_icon</Icon>}
          onNodeSelect={(event: React.SyntheticEvent, nodeIds: string) =>
            onSelected(nodeIds)
          }
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <StyledTreeItem key={"tree-item-main"} nodeId="Main" label="Main">
            {formatTreeItem([...data])}
          </StyledTreeItem>
        </TreeView>
      )}
    </Box>
  );
};
