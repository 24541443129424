import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { IRoutes } from "routes";

import {
  AnalitcsPage,
  BarGraphPage,
  IntentsPage,
  WordTreePage,
  TagsLevelsPage,
  WordCloudPage,
} from "../pages";
import { useAppSideBarContext } from "../shared/contexts";

const _routes: IRoutes[] = [
  {
    path: "/analitcs",
    label: "Analítico",
    icon: "table_chart",
    element: <AnalitcsPage />,
  },
  {
    path: "/bargraph",
    label: "Gráfico de barras",
    icon: "bar_chart",
    element: <BarGraphPage />,
  },
  {
    path: "/wordtree",
    label: "Arvore de palavras",
    icon: "polyline",
    element: <WordTreePage />,
  },
  {
    path: "/wordcloud",
    label: "Nuvem de palavras",
    icon: "bubble_chart",
    element: <WordCloudPage />,
  },
  {
    path: "/intents",
    label: "Intenções",
    icon: "transform",
    element: <IntentsPage />,
    position: "bottom",
  },
  {
    path: "/tags-levels",
    label: "Niveis de tags",
    icon: "device_hub",
    element: <TagsLevelsPage />,
    position: "bottom",
  },
];

export const AppRoutes = () => {
  const { setDrawerOptions } = useAppSideBarContext();

  useEffect(() => {
    setDrawerOptions(
      _routes
        .filter(({ label, icon }) => label !== undefined && icon !== undefined)
        .map(({ path, label, icon, position }) => {
          return {
            path,
            label: label || "",
            icon: icon || "",
            position: position || "top",
          };
        })
    );
  }, [setDrawerOptions]);

  return (
    <Routes>
      {_routes.map(({ path, element }, index) => (
        <Route key={`route-${index}`} path={path} element={element} />
      ))}
      <Route path="*" element={<Navigate to="/analitcs" />} />
    </Routes>
  );
};
