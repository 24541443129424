import Tree from "react-d3-tree";
import { useEffect, useState } from "react";

import { ITagLevel } from "data-context";

import { DataLayout } from "../../shared/layouts";
import { retrieveTagsLevels } from "../../shared/services";
import {
  useAppAlertContext,
  useAppDataContext,
  useAppUserContext,
} from "../../shared/contexts";

export const WordTreePage = () => {
  const { email } = useAppUserContext();
  const { showAlert } = useAppAlertContext();
  const { graphsData, agent } = useAppDataContext();

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (!email) {
      showAlert({
        severity: "error",
        title: "Falha",
        message: "Você não esta autenticado para executar esta ação.",
      });

      setLoading(false);

      return;
    }
    retrieveTagsLevels(email, agent)
      .then((tagsLevels) => {
        if (!graphsData) return;

        const { wordTreeData } = graphsData;

        /*
        let index = 0;
        const formatWordTreeData: (
          data: ITagLevel,
          parent?: number
        ) => any[][] = (data: ITagLevel, parent?: number) => {
          const { name, childrens } = data;
          const size = wordTreeData[name];
          const me = index++;

          let tmp = [
            [
              me,
              `${name}${
                parent === undefined ? "" : `: ${size === undefined ? 0 : size}`
              }`,
              parent === undefined ? -1 : parent,
              1, //size === undefined ? 1 : size + 1,
            ],
          ];

          if (childrens !== undefined)
            for (const c of childrens) {
              tmp = [...tmp, ...formatWordTreeData(c, me)];
            }

          return tmp;
        };*/

        const formatWordTreeData: (data: ITagLevel, first?: boolean) => any = (
          data,
          first = true
        ) => {
          const { name, childrens } = data;
          const size = wordTreeData[name];
          const children = childrens?.map((c) => formatWordTreeData(c, false));

          return first
            ? {
                name,
                children,
              }
            : {
                name,
                attributes: {
                  size: size || 0,
                },
                children,
              };
        };

        setData(formatWordTreeData(tagsLevels as ITagLevel));
      })
      .catch((err) => {
        console.error(err);

        showAlert({
          severity: "error",
          title: "Falha",
          message: "Ocorreu um erro na apresentação do mapa mental.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [graphsData, showAlert, agent]);

  return (
    <DataLayout
      title="Árvore de palavras"
      hasData={data !== undefined}
      showAgentFilter={true}
      showIntervalFilter={true}
      loading={loading}
    >
      <Tree
        data={data}
        pathFunc="diagonal"
        enableLegacyTransitions={true}
        transitionDuration={500}
        nodeSize={{ x: 500, y: 500 }}
        dimensions={{ height: 700, width: 700 }}
      />
    </DataLayout>
  );
};

/*      <Chart
chartType="WordTree"
data={[["id", "childLabel", "parent", "size"], ...data]}
width="500%"
options={{
  colors: ["black"],
  theme: "maximized",
  wordtree: {
    format: "explicit",
    word: "tags",
  },
}}
/>*/
