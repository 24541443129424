import {
  IGetUsersMeResponse,
  ILoginImplicitGrant,
  IOAuthSuccess,
  ISDKError,
} from "purecloud-service";

const _platformClient = window.require("platformClient");

const _implicitToken = process.env.REACT_APP_PURECLOUD_IMPLICITY_TOKEN;

const _client = _platformClient.ApiClient.instance;

export function oAuth(): Promise<IOAuthSuccess> {
  console.log("[oAuth] Initializing...");
  const { origin } = window.location;

  const redirectUrl = `${origin}/analitcs`;
  console.log(`[oAuth] redirectUrl: ${redirectUrl}`);

  if (_implicitToken === undefined) throw new Error("Invalid implicit token");

  return new Promise((resolve, reject) => {
    _client.setEnvironment("mypurecloud.com");

    _client.setPersistSettings(true, "S4ME");

    _client
      .loginImplicitGrant(_implicitToken, redirectUrl)
      .then((res: ILoginImplicitGrant) => {
        console.log(`[loginImplicitGrant] Authenticated!`);
        const usersApi = new _platformClient.UsersApi();

        let opts = {
          expand: ["groups"],
        };

        usersApi
          .getUsersMe(opts)
          .then(({ email }: IGetUsersMeResponse) => {
            console.log(`[getUsersMe] ${email}`);

            resolve({
              status: true,
              email,
            });
          })
          .catch((err: ISDKError) => {
            console.error(
              `[getUsersMe - Error] Error on get User ${err.status} - ${err.statusText}`
            );
            reject({
              status: false,
            });
          });
      })
      .catch((err: ISDKError) => {
        console.error(
          `[loginImplicitGrant - Error] Error on authenticate ${err.status} - ${err.statusText}`
        );
        reject({
          status: false,
        });
      });
  });
}
