import { IRawData, ITagLevel, IIntent } from "data-context";

const _botTagsUrl = process.env.REACT_APP_BOT_TAG_URL;
const _botTagsToken = process.env.REACT_APP_BOT_TAG_TOKEN;

const _awaysTwo = (value: number) => (value < 10 ? `0${value}` : value);
const _formatDate = (date: Date) =>
  `${date.getUTCFullYear()}-${_awaysTwo(date.getUTCMonth() + 1)}-${_awaysTwo(
    date.getUTCDate()
  )}T${_awaysTwo(date.getUTCHours())}:${_awaysTwo(
    date.getUTCMinutes()
  )}:${_awaysTwo(date.getUTCSeconds())}`;

export const retrieveTags = (
  email: string,
  startDate: Date,
  endDate: Date
): Promise<Array<IRawData>> => {
  console.log("Retrieve startDate", startDate, "endDate", endDate);

  return new Promise(async (resolve, reject) => {
    fetch(
      `${_botTagsUrl}/tags?${new URLSearchParams({
        StartDate: _formatDate(startDate),
        EndDate: _formatDate(endDate),
      })}`,
      {
        headers: {
          "x-email": email,
          Authorization: `bearer ${_botTagsToken}`,
        },
      }
    )
      .then(async (res) => {
        const { status } = res;
        if (status !== 200)
          return reject(`Received from bot-tag-service: ${status}`);
        return res.json();
      })
      .then(resolve)
      .catch(reject);
  });
};

export const retrieveIntents = (email: string): Promise<Array<any>> => {
  return new Promise(async (resolve, reject) => {
    fetch(`${_botTagsUrl}/intents`, {
      headers: {
        "x-email": email,
        Authorization: `bearer ${_botTagsToken}`,
      },
    })
      .then(async (res) => {
        const { status } = res;
        if (status !== 200)
          return reject(`Received from bot-tag-service: ${status}`);
        return res.json();
      })
      .then(resolve)
      .catch(reject);
  });
};

export const updateIntent = (
  email: string,
  intent: string,
  tag: IIntent
): Promise<void> => {
  console.log("Updating the intent", intent, "with tag", tag);

  return new Promise(async (resolve, reject) => {
    fetch(`${_botTagsUrl}/intents`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-email": email,
        Authorization: `bearer ${_botTagsToken}`,
      },
      body: JSON.stringify({ ...tag, intent }),
    })
      .then(async (res) => {
        const { status } = res;
        if (status !== 204)
          return reject(`Received from bot-tag-service: ${status}`);
        return resolve();
      })
      .then(resolve)
      .catch(reject);
  });
};

export const deleteIntent = (email: string, intent: string): Promise<void> => {
  console.log("Deleting the intent", intent);

  return new Promise(async (resolve, reject) => {
    fetch(`${_botTagsUrl}/intents`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-email": email,
        Authorization: `bearer ${_botTagsToken}`,
      },
      body: JSON.stringify({ intent }),
    })
      .then(async (res) => {
        const { status } = res;
        if (status !== 204)
          return reject(`Received from bot-tag-service: ${status}`);
        return resolve();
      })
      .then(resolve)
      .catch(reject);
  });
};

export const retrieveTagsLevels = (
  email: string,
  origin?: string
): Promise<Array<ITagLevel> | ITagLevel> => {
  return new Promise(async (resolve, reject) => {
    fetch(
      `${_botTagsUrl}/tagslevels${origin !== undefined ? `/${origin}` : ""}`,
      {
        headers: {
          "x-email": email,
          Authorization: `bearer ${_botTagsToken}`,
        },
      }
    )
      .then(async (res) => {
        const { status } = res;
        if (status !== 200)
          return reject(`Received from bot-tag-service: ${status}`);
        return res.json();
      })
      .then((res) => {
        origin === undefined
          ? resolve(res as Array<ITagLevel>)
          : resolve(res as ITagLevel);
      })
      .catch(reject);
  });
};

export const updateTagsLevels = (
  email: string,
  data: Array<ITagLevel>
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    fetch(`${_botTagsUrl}/tagslevels`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-email": email,
        Authorization: `bearer ${_botTagsToken}`,
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const { status } = res;
        if (status !== 204)
          return reject(`Received from bot-tag-service: ${status}`);
        return resolve();
      })
      .catch(reject);
  });
};
