import { Typography, useMediaQuery } from "@mui/material";

import { ITableItemHeader } from "table-item";

import { DataLayout } from "../../shared/layouts";
import { TableItem } from "../../shared/components";
import { useAppDataContext, useAppThemeContext } from "../../shared/contexts";

const _tableHeaders: ITableItemHeader[] = [
  { kind: "datetime", label: "Timestamp", key: "Timestamp", order: true },
  { kind: "string", label: "Agente", key: "Agent", filter: true },
  { kind: "string", label: "Cliente", key: "CustomerId", filter: true },
  {
    kind: "string",
    label: "Conversation Id",
    key: "ConversationId",
    filter: true,
  },
  { kind: "string", label: "Mensagem", key: "Message", filter: true },
  { kind: "string", label: "Resposta", key: "Response", filter: true },
  { kind: "boolean", label: "Transferido", key: "Transfer", filter: true },
  /*{
    kind: "boolean",
    label: "Finalização Bot",
    key: "BotEndInteraction",
    filter: true,
  },*/
  { kind: "string", label: "Tag", key: "Tag", filter: true },
  { kind: "string", label: "Intenção", key: "Intent", filter: true },
];

export const AnalitcsPage = () => {
  const { theme } = useAppThemeContext();
  const { rawData, interval } = useAppDataContext();

  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <DataLayout
      title="Analítico"
      hasData={rawData !== undefined}
      showIntervalFilter={true}
    >
      <TableItem
        title={`Tag(s) no periodo: ${interval.startDate?.toLocaleString()} até ${interval.endDate?.toLocaleString()}`}
        headers={_tableHeaders}
        rawData={rawData || []}
        noData={
          <Typography
            sx={{ margin: "auto" }}
            variant={smDown ? "h5" : mdDown ? "h4" : "h3"}
          >
            Sem dados para exibir
          </Typography>
        }
        actions={{
          insert: false,
          update: false,
          remove: false,
          download: true,
        }}
        stickyHeader
      />
    </DataLayout>
  );
};
