import React, { useEffect, useState } from "react";
import { Box, Divider, Drawer, List, useMediaQuery } from "@mui/material";

import { ListItemLink } from "../list-item/ListItem";
import { useAppSideBarContext, useAppThemeContext } from "../../contexts";

interface IFuncComponentsProps {
  children?: React.ReactNode;
  marginTop?: string;
}

const _drawerMinWidht = 7;
const _drawerWidht = 28;

export const SideBar: React.FC<IFuncComponentsProps> = ({
  children,
  marginTop,
}) => {
  const { theme } = useAppThemeContext();
  const { isOpen, drawerOptions, toggleSideBar } = useAppSideBarContext();

  const [sidebarWidth, setSideBarWidth] = useState(
    theme.spacing(_drawerMinWidht)
  );

  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(
    () =>
      setSideBarWidth(
        !mdDown && isOpen
          ? theme.spacing(_drawerWidht)
          : theme.spacing(_drawerMinWidht)
      ),
    [mdDown, isOpen, theme]
  );

  return (
    <Box height="100%" width="100%" display="flex" overflow="hidden">
      <Drawer variant="permanent">
        <Box height={marginTop} />
        <Box
          width={sidebarWidth}
          height="100vh"
          display="flex"
          flexDirection="column"
          overflow="hidden"
          sx={{
            transition: "all .3s ease-in-out",
          }}
        >
          <Box flex={1}>
            <List component="nav">
              {drawerOptions
                .filter(({ position }) => position === "top")
                .map(({ icon, label, path }, index) => (
                  <ListItemLink
                    key={`list-item-link.top-${index}`}
                    icon={icon}
                    label={!mdDown && isOpen ? label : undefined}
                    to={path}
                  />
                ))}
            </List>
          </Box>
          <Divider />
          <List component="nav">
            {drawerOptions
              .filter(({ position }) => position === "bottom")
              .map(({ icon, label, path }, index) => (
                <ListItemLink
                  key={`list-item-link-botoom-${index}`}
                  icon={icon}
                  label={!mdDown && isOpen ? label : undefined}
                  to={path}
                />
              ))}
            {!mdDown && (
              <ListItemLink
                icon={
                  !mdDown && isOpen
                    ? "keyboard_double_arrow_left"
                    : "keyboard_double_arrow_right"
                }
                label={!mdDown && isOpen ? "Ocultar" : undefined}
                onClick={toggleSideBar}
              />
            )}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        flexGrow={1}
        maxWidth={`calc(100vw - ${sidebarWidth})`}
        maxHeight="100vh"
        overflow="hidden"
        sx={{
          transition: "all .3s ease-in-out",
          marginLeft: sidebarWidth,
        }}
        display="flex"
        flexDirection="column"
        height="100%"
      >
        {children}
      </Box>
    </Box>
  );
};
