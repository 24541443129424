import { useState } from "react";

import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { IDateRangePickerProps } from "date-range-picker";

export const DateRangePicker: React.FC<IDateRangePickerProps> = ({
  startDate,
  endDate,
}) => {
  const [start, setStart] = useState<Dayjs | null>(startDate.current);
  const [end, setEnd] = useState<Dayjs | null>(endDate.current);

  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" gap={theme.spacing(1)} marginTop={theme.spacing(3)}>
        <DatePicker
          disableFuture
          maxDate={end}
          label={smDown ? "Inicio" : "Data Inicial"}
          value={start}
          onChange={(e) => {
            setStart(e);
            startDate.current = e;
          }}
        />
        <Box margin="auto">
          <Typography
            variant={smDown ? "h6" : mdDown ? "h5" : "h4"}
            margin="auto"
          >
            -
          </Typography>
        </Box>
        <DatePicker
          disableFuture
          minDate={start}
          label={smDown ? "Fim" : "Data Final"}
          value={end}
          onChange={(e) => {
            setEnd(e);
            endDate.current = e;
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};
