import { CSVLink } from "react-csv";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
  Box,
  CircularProgress,
  Fab,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import {
  ITableItemPagination,
  ITableItemProps,
  ITableItemReducerAction,
  ITableItemState,
  TTableItemKind,
} from "table-item";

import { useAppThemeContext } from "../../contexts";

function reducer(state: ITableItemState, action: ITableItemReducerAction) {
  const { type, payload } = action;

  switch (type) {
    case "SET_ORDERBY":
      return { ...state, orderBy: payload };
    case "RESET_ORDERBY":
      return { ...state, orderBy: undefined };
    case "SET_FILTER":
      return { ...state, filter: { ...state.filter, ...payload } };
    case "RESET_FILTER":
      return { ...state, filter: undefined };
    default:
      return state;
  }
}

function _textFormatter(kind: TTableItemKind, value: any) {
  switch (kind) {
    case "boolean":
      return value ? <Icon>check</Icon> : <Icon>clear</Icon>;
    case "datetime":
      return new Date(value).toLocaleString();
    case "string":
    default:
      return value;
  }
}

function _awaysTwo(n: number) {
  return n < 10 ? `0${n}` : `${n}`;
}

function _generateFileName() {
  const date = new Date();

  return `tags_${date.getFullYear()}${_awaysTwo(date.getUTCMonth())}${_awaysTwo(
    date.getUTCDay()
  )}${_awaysTwo(date.getUTCHours())}${_awaysTwo(
    date.getUTCMinutes()
  )}${_awaysTwo(date.getUTCSeconds())}${_awaysTwo(
    date.getUTCMilliseconds()
  )}.csv`;
}

const initialState: ITableItemState = {};

export const TableItem: React.FC<ITableItemProps> = ({
  title,
  headers,
  rawData,
  actions,
  noData,
  stickyHeader,
  onInsert,
  onUpdate,
  onRemove,
}) => {
  const { theme } = useAppThemeContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pagination, setPagination] = useState<ITableItemPagination>({
    page: 0,
    rowsPerPage: 5,
  });
  const [filteredData, setFilteredData] = useState(rawData);
  const [loading, setLoading] = useState(true);

  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const rawDataMemo = useMemo(() => rawData, [rawData]);

  const filterData = useCallback(async () => {
    const { orderBy, filter } = state;

    setLoading(true);

    await new Promise<void>(async (resolve) => {
      let filtered = [...rawDataMemo];

      if (filter && filter.value !== undefined)
        filtered = filtered.filter((a) => {
          return (
            (a.hasOwnProperty(filter.label) &&
              filter.kind === "string" &&
              a[filter.label] !== null &&
              a[filter.label] !== undefined &&
              a[filter.label]
                .toLowerCase()
                .includes(filter.value.toLowerCase())) ||
            (filter.kind === "boolean" &&
              a[filter.value] === (filter.value === "true"))
          );
        });

      if (orderBy) {
        filtered = filtered.sort((a, b) => {
          if (a[orderBy.label] === undefined && b[orderBy.label] === undefined)
            return 0;
          if (a[orderBy.label] === undefined) return 1;
          if (b[orderBy.label] === undefined) return -1;

          if (
            (orderBy.direction === "asc" &&
              a[orderBy.label] > b[orderBy.label]) ||
            (orderBy.direction === "desc" &&
              a[orderBy.label] < b[orderBy.label])
          )
            return -1;

          if (
            (orderBy.direction === "desc" &&
              a[orderBy.label] > b[orderBy.label]) ||
            (orderBy.direction === "asc" && a[orderBy.label] < b[orderBy.label])
          )
            return 1;

          return 0;
        });
      }

      setFilteredData(filtered);

      setLoading(false);

      setPagination((p) => {
        return { ...p, page: 0 };
      });

      resolve();
    });
  }, [state, rawDataMemo]);

  useEffect(() => {
    filterData();
  }, [rawDataMemo, state, filterData]);

  const hasFilters = headers.filter((h) => h.filter).length > 0;

  const handleOrderBy = function (label: string) {
    const { orderBy } = state;
    const isOrderColumn = orderBy?.label === label;

    if (isOrderColumn && orderBy?.direction === "desc")
      return dispatch({ type: "RESET_ORDERBY" });

    dispatch({
      type: "SET_ORDERBY",
      payload: {
        label,
        direction:
          isOrderColumn && orderBy?.direction === "asc" ? "desc" : "asc",
      },
    });
  };

  function _searchFieldFormatter(
    state: ITableItemState,
    dispatch: (payload: ITableItemReducerAction) => void
  ) {
    const { filter } = state;
    const { theme } = useAppThemeContext();

    switch (filter?.kind) {
      case "boolean":
        return (
          <FormControl
            sx={{
              minWidth: theme.spacing(15),
            }}
          >
            <InputLabel>Value</InputLabel>
            <Select
              label="Value"
              value={state.filter?.value || ""}
              sx={{
                height: theme.spacing(8),
              }}
              onChange={(e) =>
                dispatch({
                  type: e.target.value === "" ? "RESET_FILTER" : "SET_FILTER",
                  payload: {
                    value: e.target.value,
                  },
                })
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="false">
                <Icon>clear</Icon>
              </MenuItem>
              <MenuItem value="true">
                <Icon>check</Icon>
              </MenuItem>
            </Select>
          </FormControl>
        );
      case "string":
      default:
        return (
          <TextField
            label="Value"
            variant="outlined"
            InputProps={{
              endAdornment: smDown ? <></> : <Icon>search</Icon>,
            }}
            inputProps={{
              style: {
                height: theme.spacing(4),
              },
            }}
            disabled={!state?.filter?.label}
            value={state?.filter?.value || ""}
            onChange={(e) =>
              dispatch({
                type: "SET_FILTER",
                payload: { value: e.target.value },
              })
            }
          />
        );
    }
  }

  return (
    <Box height="100%" maxHeight="100%" display="flex" flexDirection="column">
      <Toolbar
        sx={{
          display: "flex",
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }}
      >
        {actions?.insert && (
          <Box>
            <Tooltip
              title="Insert"
              sx={{ marginRight: theme.spacing(1) }}
              onClick={() => onInsert && onInsert()}
            >
              <Fab
                size={lgDown ? "small" : "medium"}
                color="primary"
                aria-label="add"
              >
                <Icon>add</Icon>
              </Fab>
            </Tooltip>
          </Box>
        )}

        {!mdDown && (
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        )}

        <Box flexGrow={1} />

        {hasFilters && (
          <Box display="flex">
            <FormControl
              sx={{
                minWidth: theme.spacing(15),
                marginRight: "-1px",
              }}
            >
              <InputLabel>Key</InputLabel>
              <Select
                label="Key"
                value={state.filter?.label || ""}
                sx={{
                  height: theme.spacing(8),
                }}
                onChange={(e) =>
                  dispatch({
                    type: e.target.value === "" ? "RESET_FILTER" : "SET_FILTER",
                    payload: {
                      label: e.target.value,
                      kind: headers.filter((h) => e.target.value === h.key)?.[0]
                        ?.kind,
                      value: undefined,
                    },
                  })
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {headers
                  .filter((e) => e.filter === true)
                  .map((e, index) => (
                    <MenuItem
                      key={`header-filter-item-${index}`}
                      value={e.key}
                      selected={index === 0}
                    >
                      {e.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {_searchFieldFormatter(state, dispatch)}
          </Box>
        )}
        <Box marginLeft={theme.spacing(1)} width={theme.spacing(4)}>
          {!!loading && <CircularProgress />}
        </Box>
        {!!actions?.download && (
          <Box marginLeft={theme.spacing(2)} width={theme.spacing(4)}>
            <CSVLink
              data={filteredData}
              filename={_generateFileName()}
              separator={"|"}
              //enclosingCharacter={'"'}
            >
              <Tooltip title="Download" onClick={() => {}}>
                <Fab size="small" color="primary" aria-label="add">
                  <Icon>download_icon</Icon>
                </Fab>
              </Tooltip>
            </CSVLink>
          </Box>
        )}
      </Toolbar>

      <Box flex={1}>
        {filteredData.length === 0 ? (
          <Box height="100%" alignContent="center" display="flex">
            {noData ? (
              noData
            ) : (
              <Typography
                sx={{
                  margin: "auto",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                variant={smDown ? "h5" : mdDown ? "h4" : "h3"}
              >
                No Data
              </Typography>
            )}
          </Box>
        ) : (
          <TableContainer sx={{ maxHeight: "60vh", overflow: "auto" }}>
            <Table stickyHeader={stickyHeader}>
              <TableHead
                sx={{
                  zIndex: 999,
                }}
              >
                <TableRow>
                  {headers.map((header, i) => {
                    const isOrderColumn = state.orderBy?.label === header.key;

                    return (
                      <TableCell
                        key={`table-header-cell-${i}`}
                        align={header.headerAlign || "left"}
                        sortDirection={
                          isOrderColumn ? state.orderBy?.direction : false
                        }
                      >
                        {header.order ? (
                          <TableSortLabel
                            key={`table-header-cell-sort-${i}`}
                            active={isOrderColumn}
                            direction={
                              isOrderColumn ? state.orderBy?.direction : "asc"
                            }
                            onClick={() => handleOrderBy(header.key)}
                          >
                            <Box
                              width="100%"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                            >
                              {header.label}
                            </Box>
                          </TableSortLabel>
                        ) : (
                          <Box
                            width="100%"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                          >
                            {header.label}
                          </Box>
                        )}
                      </TableCell>
                    );
                  })}
                  {(actions?.update || actions?.remove) && (
                    <TableCell align="right" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(
                    pagination.page * pagination.rowsPerPage,
                    (pagination.page + 1) * pagination.rowsPerPage
                  )
                  .map((row, i) => (
                    <TableRow key={`table-body-row-${i}`}>
                      {headers.map((header, j) => (
                        <TableCell
                          key={`table-body-row-${i}-cell-${j}`}
                          align={header.align || "left"}
                        >
                          {_textFormatter(header.kind, row[header.key])}
                        </TableCell>
                      ))}

                      {(actions?.update || actions?.remove) && (
                        <TableCell align="right">
                          {actions?.update && (
                            <Tooltip
                              title="Edit"
                              onClick={() => onUpdate && onUpdate(row)}
                            >
                              <Fab
                                size="small"
                                color="primary"
                                aria-label="add"
                              >
                                <Icon>edit</Icon>
                              </Fab>
                            </Tooltip>
                          )}
                          {actions?.remove && (
                            <Tooltip
                              title="Remove"
                              sx={{ marginLeft: theme.spacing(1) }}
                              onClick={() => onRemove && onRemove(row)}
                            >
                              <Fab
                                size="small"
                                color="primary"
                                aria-label="add"
                              >
                                <Icon>delete</Icon>
                              </Fab>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 30]}
        sx={{ overflow: "hidden" }}
        lang="pt-BR"
        component="div"
        count={filteredData.length}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        onPageChange={(e, page) =>
          setPagination((p) => {
            return { ...p, page };
          })
        }
        onRowsPerPageChange={(e) =>
          setPagination((p) => {
            return { ...p, page: 0, rowsPerPage: parseInt(e.target.value) };
          })
        }
      />
    </Box>
  );
};
