import { Dayjs } from "dayjs";

import { DialogContentText } from "@mui/material";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  IRawData,
  IGraphsData,
  IDataContextData,
  IDataContextIntervalDate,
} from "data-context";

import { retrieveTags } from "../services";
import { useAppUserContext } from "./UserContext";
import { useAppAlertContext } from "./AlertContext";
import { ActionsDialog, DateRangePicker } from "../components";

interface IFuncComponentsProps {
  children: React.ReactNode;
}

const DataContext = createContext({} as IDataContextData);

export const useAppDataContext = () => useContext(DataContext);

export const DataContextProvider: React.FC<IFuncComponentsProps> = ({
  children,
}) => {
  const { showAlert } = useAppAlertContext();
  const { email } = useAppUserContext();
  const [interval, setInterval] = useState<IDataContextIntervalDate>({});
  const [isLoading, setIsLoading] = useState(false);
  const [openDateFilter, setOpenDateFilter] = useState(false);
  const [newRawData, setNewRawData] = useState<Array<IRawData>>();
  const [newGraphsData, setNewGraphsData] = useState<IGraphsData>();
  const [newAgent, setNewAgent] = useState<string>();
  const [newAgents, setNewAgents] = useState<Array<string>>([]);

  const startDate = useRef<Dayjs>();
  const endDate = useRef<Dayjs>();

  const agent = useMemo(() => newAgent, [newAgent]);
  const rawData = useMemo(() => newRawData, [newRawData]);
  const agents = useMemo(() => newAgents, [newAgents]);
  const graphsData = useMemo(() => newGraphsData, [newGraphsData]);

  const showDateFilterContext = useCallback(
    () => setOpenDateFilter(true),
    [setOpenDateFilter]
  );

  const setIsLoadingContext = useCallback(
    (value: boolean) => setIsLoading(value),
    [setIsLoading]
  );

  const formatGraphsData = (data: Array<IRawData>): IGraphsData => {
    const datas: IGraphsData = {
      barGraphData: [],
      wordCloudData: [],
      wordTreeData: {},
    };

    try {
      if (!data || data.length === 0) return datas;
      const tags: any = {};
      const intents: any = {};

      for (const { Tag, Intent } of data) {
        if (tags.hasOwnProperty(Tag)) tags[Tag] += 1;
        else tags[Tag] = 1;

        if (Intent !== null && Intent !== undefined) {
          if (intents.hasOwnProperty(Intent)) intents[Intent] += 1;
          else intents[Intent] = 1;
        }
      }

      //Bar Graph Data
      try {
        datas.barGraphData = Object.keys(tags)
          .map((t) => [t, tags[t] as number])
          .sort((a, b) => (b[1] as number) - (a[1] as number));
      } catch (err) {
        console.error(err);

        showAlert({
          severity: "error",
          title: "Falha",
          message: "Ocorreu um erro na apresentação do gráfico de barras.",
        });
      }

      //Word Cloud Data
      try {
        datas.wordCloudData = Object.keys(intents).map((i) => {
          return { text: i, value: intents[i] as number };
        });
      } catch (err) {
        console.error(err);

        showAlert({
          severity: "error",
          title: "Falha",
          message: "Ocorreu um erro na apresentação da nuvem de palavras.",
        });
      }

      //Word Tree Data
      try {
        datas.wordTreeData = tags;
      } catch (err) {
        console.error(err);

        showAlert({
          severity: "error",
          title: "Falha",
          message: "Ocorreu um erro na apresentação da nuvem de palavras.",
        });
      }
    } catch (err) {
      console.error(err);

      showAlert({
        severity: "error",
        title: "Falha",
        message: "Ocorreu um erro na apresentação dos dados.",
      });
    } finally {
      return datas;
    }
  };

  const fixDate = (date?: Dayjs, kind?: "start" | "end") => {
    if (date === undefined) return undefined;

    const tmp = new Date(date.valueOf());
    if (kind === "end") {
      tmp.setHours(23);
      tmp.setMinutes(59);
      tmp.setSeconds(59);
    } else {
      tmp.setHours(0);
      tmp.setMinutes(0);
      tmp.setSeconds(0);
    }

    return tmp;
  };

  const handleAgent = (agent: string): void => {
    setNewAgent(agent);
    setIsLoading(true);

    try {
      const graphsData = formatGraphsData(
        rawData?.filter((d) => d.Agent === agent) || []
      );
      setNewGraphsData(graphsData);

      showAlert({
        severity: "success",
        title: "Sucesso",
        message: "Dados filtrados com sucesso.",
      });
    } catch (err) {
      console.error(err);

      showAlert({
        severity: "error",
        title: "Falha",
        message: "Ocorreu um erro ao aplicar o filtro nos dados.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadData = useCallback(
    (
      startDate = interval.startDate,
      endDate = interval.endDate
    ): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (startDate === undefined || endDate === undefined)
          return showAlert({
            severity: "error",
            title: "Falha",
            message: "O intervalo de datas não foi definido.",
          });

        if (!email)
          return showAlert({
            severity: "error",
            title: "Falha",
            message: "Você não esta autenticado para executar esta ação.",
          });

        setIsLoading(true);

        retrieveTags(email, startDate, endDate)
          .then((data) => {
            setNewRawData(data);

            const uniqueAgents = data
              .map((d) => d.Agent)
              .filter((value, index, array) => array.indexOf(value) === index);
            setNewAgents(uniqueAgents);

            showAlert({
              severity: "success",
              title: "Sucesso",
              message: "Dados coletados com sucesso.",
            });
          })
          .catch((err) => {
            console.error(err);
            showAlert({
              severity: "error",
              title: "Falha",
              message: "Ocorreu um erro na coleta dos dados.",
            });
          })
          .finally(() => {
            resolve();
            setIsLoading(false);
          });
      });
    },
    [email, interval]
  );

  const handlerFilter = (): Promise<void> => {
    setOpenDateFilter(false);

    return new Promise((resolve) => {
      const tmpStartDate = fixDate(startDate.current, "start");
      const tmpEndDate = fixDate(endDate.current, "end");

      if (tmpStartDate === undefined) {
        console.error("Invalid start date filter", startDate.current);

        showAlert({
          severity: "error",
          title: "Falha",
          message: "Selecione uma data inicial válida.",
        });
        return resolve();
      }
      if (tmpEndDate === undefined) {
        console.error("Invalid end date filter", endDate.current);

        showAlert({
          severity: "error",
          title: "Falha",
          message: "Selecione uma data final válida.",
        });
        return resolve();
      }

      setInterval({ startDate: tmpStartDate, endDate: tmpEndDate });

      loadData(tmpStartDate, tmpEndDate).finally(resolve);
    });
  };

  return (
    <DataContext.Provider
      value={{
        rawData,
        graphsData,
        agent,
        agents,
        interval,
        isLoading,
        setAgent: handleAgent,
        setIsLoading: setIsLoadingContext,
        showDateFilter: showDateFilterContext,
        loadData,
      }}
    >
      <>
        {children}
        {openDateFilter === true && (
          <ActionsDialog
            title="Filtro"
            open={openDateFilter}
            onClose={() => setOpenDateFilter(false)}
            onConfirm={handlerFilter}
            onCancel={() => setOpenDateFilter(false)}
            confirmLabel="Filtrar"
            cancelLabel="Cancelar"
            showLoading={false}
            transitionDirection="down"
          >
            <DialogContentText>
              Selecione um intervalo entre as datas para filtrar as intenções.
            </DialogContentText>
            <DateRangePicker startDate={startDate} endDate={endDate} />
          </ActionsDialog>
        )}
      </>
    </DataContext.Provider>
  );
};
